var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-content',[_c('page-title',{attrs:{"heading":"การออกใบประกาศหลังเรียนจบ"},scopedSlots:_vm._u([{key:"right",fn:function(){return undefined},proxy:true}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('filter-fiscal-year-term',{model:{value:(_vm.formFiscalYearTerm),callback:function ($$v) {_vm.formFiscalYearTerm=$$v},expression:"formFiscalYearTerm"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('filter-master',{attrs:{"m-headquarter-id":!_vm.authUser.canAccessAllHeadQuarters ? _vm.authUser.mHeadquarterId : null,"m-division-id":!_vm.authUser.canAccessAllDivisions ? _vm.authUser.mDivisionId : null,"m-station-id":!_vm.authUser.canAccessAllStations ? _vm.authUser.mStationId : null,"disabledInput":{
          headQuarter:
            !_vm.authUser.canAccessAllHeadQuarters &&
            _vm.authUser.mHeadquarterId !== null,
          division:
            !_vm.authUser.canAccessAllDivisions && _vm.authUser.mDivisionId !== null,
          station:
            !_vm.authUser.canAccessAllStations && _vm.authUser.mStationId !== null,
        },"hiddenInput":{ school: true, schoolClass: true, room: true }},model:{value:(_vm.formfilter),callback:function ($$v) {_vm.formfilter=$$v},expression:"formfilter"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('filter-date-between',{model:{value:(_vm.filterDateBetween),callback:function ($$v) {_vm.filterDateBetween=$$v},expression:"filterDateBetween"}})],1)],1),_c('b-row',{staticClass:"m-0 mt-3"},[_c('b-col',{staticClass:"px-2 mb-2",staticStyle:{"font-size":"18px"},attrs:{"sm":"12"}},[_vm._v("จำนวนการออกใบประกาศหลังเรียนจบ")]),_c('b-col',{staticClass:"px-2 mt-4 mt-sm-0",attrs:{"sm":"12"}},[_c('ul',{staticClass:"mb-0 pl-3"},[_c('li',[_c('b',[_vm._v("ยอดรวมทั้งหมด:")]),_vm._v(" "+_vm._s(parseInt(_vm.reportIssueCertificated.count_issued).toLocaleString() || 0)+" ครั้ง ")])])])],1),_c('teaching-dare-table',{ref:"table",staticClass:"mt-4",attrs:{"fields":_vm.fields,"params":_vm.TableParams,"action-label":"","show-action":false,"see-detail":true,"show-delete":false,"isReport":true},on:{"seeDetail":_vm.onSeeDetail,"delete":_vm.onDelete,"reportIssueCert":_vm.setReportIssueCert}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }